<route>
{
  "meta": {
    "auth": "storeManage"
  }
}
</route>

<template>
  <div>
    <bm-cardTabList :list="tabList" current="门店管理"></bm-cardTabList>

    <div class="tableContainer">
      <div class="searchContainer">
        <el-row :gutter="20">
          <el-col :span="8">
            <span class="searchText">门店名称</span>
            <el-input class="searchInput" v-model.trim="searchValue.storeTitle" placeholder="门店名称" clearable>
            </el-input>
          </el-col>

          <el-col :span="8">
            <span class="searchText">绑定二级商户号</span>
            <el-select
              class="searchInput"
              v-model="searchValue.bindedSubMerchant"
              placeholder="请选择是否绑定二级商户号"
            >
              <el-option :value="''" label="全部"> 全部</el-option>
              <el-option :value="0" label="不绑定"> 不绑定</el-option>
              <el-option :value="1" label="绑定"> 绑定</el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            <span class="searchText">隐藏状态</span>
            <el-select class="searchInput" v-model="searchValue.hideStatus" placeholder="请选择隐藏状态">
              <el-option :value="''" label="全部"> 全部</el-option>
              <el-option :value="0" label="显示"> 显示</el-option>
              <el-option :value="1" label="隐藏"> 隐藏</el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="query">
          <el-col :span="8" v-show="areaList.length > 1">
            <span class="searchText">地区</span>
            <el-select class="searchInput" v-model="searchValue.areaId" placeholder="请选择地区">
              <el-option :value="''" label="全部"> 全部</el-option>
              <el-option v-for="item in areaList" :value="item.id" :key="item.id" :label="item.name"> </el-option>
            </el-select>
          </el-col>
          <el-col :span="8" :offset="8">
            <el-button type="info" @click="resetSearch()">重置</el-button>
            <el-button type="primary" @click="getList()"> 搜索</el-button></el-col
          >
        </el-row>
      </div>
      <div class="header" style="margin: 20px 0">
        <span class="title">门店列表</span>
        <el-button type="success" style="float: right" @click="edit()">添加</el-button>
        <div class="uploader" style="float: right; margin-right: 10px">
          <input
            type="file"
            ref="checkedCodeUploader"
            :accept="$store.getters['upload/fileAccept'].excel"
            @input="onCheckedFileChange"
          />
          <el-button type="success">批量添加</el-button>
        </div>
      </div>
      <el-table
        :data="list"
        v-loading="loading"
        style="width: 100%"
        ref="multipleTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column v-for="col in Object.keys(label)" :key="col" :prop="col" :label="label[col]" align="center">
          <template slot-scope="{ row }">
            <div v-if="col === 'businessDistrictTitles'">
              {{ row[col].join(',') }}
            </div>
            <div v-else-if="col === 'hideStatus'">
              <el-switch
                v-model="row.hideStatus"
                @change="handleHideStatusChange(row)"
                :active-value="0"
                :inactive-value="1"
              ></el-switch>
            </div>
            <div v-else-if="col === 'bindedSubMerchant'">
              {{ row.bindedSubMerchant === 0 ? '否' : '是' }}
            </div>
            <div v-else-if="col === 'draft'">
              {{ row.draft === 0 ? '已完成' : '未完成' }}
            </div>
            <div v-else>{{ row[col] }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="{ row, $index }">
            <el-button @click="edit(row, false, true)" type="text" size="small">编辑</el-button>
            <el-button @click="edit(row, true)" type="text" size="small">查看</el-button>
            <el-button type="text" size="small" @click="handelDel(row.id, $index)" class="deleteBtnText"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <div class="Selection">
          <el-button type="info" @click="toggleSelection(list)">反选</el-button>
          <el-button type="primary" @click="confirmChangeStatus(0)">批量显示</el-button>
          <el-button type="primary" @click="confirmChangeStatus(1)">批量隐藏</el-button>

          <el-button type="primary" v-if="$store.getters['vendor/params/getBusinessModel'] == 0" @click="showRelate()"
            >关联二级商户号</el-button
          >
        </div>
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog :close-on-click-modal="false" :visible.sync="relate.show" title="关联二级商户号" width="350px">
      <el-select class="selectStoreAccount" @change="handleShareStatus" v-model="relate.subMchid" placeholder="请选择">
        <el-option
          v-for="item in subMChildOptions"
          :key="item.subMchid"
          :label="item.accountName"
          :value="item.subMchid"
        >
          <span style="float: left">{{ item.accountName }}</span>
          <span style="float: right; color: #8492a6; font-size: 13px">{{ item.remark }}</span>
        </el-option>
      </el-select>

      <el-tag style="margin-left: 8px" type="primary" v-if="relate.status === 1">取用</el-tag>
      <el-tag style="margin-left: 8px" type="success" v-else-if="relate.status === 0">自有</el-tag>
      <div v-else></div>
      <div slot="footer">
        <el-button round @click="relate.show = false">取消</el-button>
        <el-button round type="primary" :disabled="!relate.subMchid" @click="confirmRelate">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { tabListIncludeAccount, tabListExcludeAccount } from '../tabList'
import { pagination } from '@/mixin/pagination'
export default {
  name: 'businessStoreList',
  mixins: [pagination],
  data() {
    return {
      pageSizes: [10, 20, 50, 100, 200, 500, 1000],
      number: 0,
      tipDialog: false,
      tabList: [],
      hideStatusParamList: [],
      label: {
        storeTitle: '门店名称',
        businessDistrictTitles: '所属商圈',
        exchangeCheckCode: '核销验证码',
        storeTelephone: '联系方式',
        hideStatus: '是否显示',
        draft: '状态',
        storeStaffCount: '门店人员数'
      },
      list: [],
      subMChildOptions: [],
      relate: {
        status: -1,
        show: false,
        subMchid: ''
      },
      searchValue: {
        bindedSubMerchant: '',
        storeTitle: '',
        hideStatus: '',
        businessId: this.$store.getters['vendor/params/getVendorId'],
        areaId: ''
      },
      checkedCodeData: {
        id: '',
        file: null,
        fileName: '',
        fileList: []
      },
      areaList: []
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$store.getters['vendor/params/getBusinessModel'] == 0) {
        vm.tabList = tabListIncludeAccount
      } else {
        vm.tabList = tabListExcludeAccount
      }
      vm.searchValue.businessId = vm.$store.getters['vendor/params/getVendorId']
      vm.getList()
      vm.subMerchant()
      vm.getDetail()
    })
  },
  mounted() {
    if (this.$store.getters['vendor/params/getBusinessModel'] == 0) {
      this.label.bindedSubMerchant = '是否关联二级商户号'
    }
  },
  methods: {
    onCheckedFileChange(e) {
      if (e.target.files.length) {
        let businessId = this.$store.getters['vendor/params/getVendorId']
        const file = e.target.files[0]

        if (file.size > 10240000) {
          this.$message.error('文件过大，大于10M，请分开上传')
          return
        }
        this.checkedCodeData.file = file
        this.checkedCodeData.fileName = file.name
        const loading = this.$loading()
        let formData = new FormData()
        formData.append('file', this.checkedCodeData.file)
        this.$http
          .post(
            process.env.VUE_APP_BASE_URL + `boom-mix-biz-service/sysAdmin/store/batchImport/${businessId}`,
            formData
          )
          .then(res => {
            this.$message.success('添加成功！')
            this.getList()
          })
          .catch(err => {
            this.$message.error(err.msg)
          })
          .finally(() => {
            this.$refs.checkedCodeUploader.value = null
            loading.close()
          })
      }
    },
    handleImport() {
      let businessId = this.$store.getters['vendor/params/getVendorId']
      this.$refs.batchImport.openModal(
        {
          upload: true
        },
        process.env.VUE_APP_BASE_URL + `boom-mix-biz-service/sysAdmin/store/batchImport/${businessId}`
      )
    },
    onImportSuccess() {
      this.loadData()
    },
    getDetail() {
      this.areaList = []
      this.$api.vendor.vendor('getBusinessDetail', this.$store.getters['vendor/params/getVendorId']).then(res => {
        res.areaIds.forEach(item => {
          this.$store.getters['user/userAreaInfoList'].forEach(childrenItem => {
            if (item === childrenItem.id) {
              this.areaList.push(childrenItem)
            }
          })
        })
      })
    },
    handleShareStatus(val) {
      this.subMChildOptions.forEach(item => {
        if (item.subMchid === val) this.relate.status = item.shareStatus
      })
    },
    subMerchant() {
      this.$api.vendor
        .vendor('getSubMerchant', {
          params: { businessId: this.$store.getters['vendor/params/getVendorId'] }
        })
        .then(res => {
          this.subMChildOptions = []
          if (res.length) {
            this.subMChildOptions = res
          }
        })
        .catch(err => {
          this.subMChildOptions = []
          this.$message.error(err.msg || '操作失败')
        })
    },
    showRelate() {
      if (this.hideStatusParamList.length === 0) {
        this.$message.error('请先选择门店')
        return
      }
      this.relate.show = true
      this.relate.subMchid = ''
      this.relate.status = -1
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange(val) {
      this.hideStatusParamList.splice(0)
      val.forEach(i => {
        let obj = {}
        obj.id = i.id
        this.hideStatusParamList.push(obj)
      })
    },
    handleHideStatusChange(row) {
      let params = [
        {
          id: row.id,
          hideStatus: row.hideStatus
        }
      ]

      this.$api.vendor
        .vendor('putStoreStatus', params)
        .then(res => {
          this.$message.success('更新成功')
          this.getList(true)
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    confirmRelate() {
      const storeIds = this.hideStatusParamList.map(item => {
        return item.id
      })
      this.$http
        .put('boom-center-product-service/sysAdmin/store/list/store/subMchid', {
          businessId: this.$store.getters['vendor/params/getVendorId'],
          storeIds: storeIds,
          subMchid: this.relate.subMchid
        })
        .then(res => {
          this.$message.success('关联成功')
          this.relate.show = false
          this.getList(true)
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    confirmChangeStatus(val) {
      if (this.hideStatusParamList.length === 0) {
        this.$message.error('请先选择门店')
        return
      }
      this.hideStatusParamList.map(item => {
        item.hideStatus = val
        return item
      })
      this.$api.vendor
        .vendor('putStoreStatus', this.hideStatusParamList)
        .then(res => {
          this.$message.success('更新成功')

          this.getList(true)
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    resetSearch() {
      this.searchValue = {
        bindedSubMerchant: '',
        storeTitle: '',
        hideStatus: '',
        businessId: this.$store.getters['vendor/params/getVendorId'],
        areaId: ''
      }
      this.getList()
    },
    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1

      if (this.searchValue.storeTitle === '') {
        delete this.searchValue.storeTitle
      }
      this.$api.vendor
        .vendor('storeListHasPerson', {
          params: {
            page: this.currentPage,
            size: this.pageSize,
            ...this.searchValue
          },
          headers: {
            ['Bm-Area-Id']: this.searchValue.areaId
          }
        })
        .then(res => {
          this.list = res.list
          this.total = res.total
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },

    edit(row, check = false, isEdit = false) {
      let id = row ? row.id : '-1'
      this.$store.commit('vendor/params/updateParams', { key: 'storeId', value: id })
      this.$store.commit('vendor/params/updateParams', { key: 'storeCheck', value: check })
      this.$store.commit('vendor/params/updateParams', { key: 'isEdit', value: isEdit })

      this.$router.push('/vendor/vendorMange/store/edit')
    },
    handelDel(id, index) {
      this.$confirm('确定要删除此门店吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http
          .delete(`boom-mix-biz-service/sysAdmin/store/${id}`)
          .then(res => {
            if (res.state !== 0) {
              this.$message({
                message: `删除成功`,
                type: 'success'
              })
              this.list.splice(index, 1)
            } else {
              this.$message.error(res.msg || '删除失败')
            }
          })
          .catch(err => {
            this.$message.error(err.msg || '操作失败')
          })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.uploader {
  position: relative;
  right: 0;
  input {
    .wrap;
    width: 100%;
    opacity: 0;
    position: absolute;
  }
}
.query {
  margin-top: 20px;
}
.tableContainer {
  background-color: #fff;
  padding: @container-margin;
}
.selectStoreAccount {
  width: 300px;
}
.footer {
  display: flex;
  justify-content: space-between;
  .Selection {
    .status {
      width: 100px;
      margin: 0 10px;
    }
  }
}
</style>
